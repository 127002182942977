<template>
  <div class="main-content">
        <page-header title="Fleet" pre-title="Overview" :loading="false">
      <template slot="actions">
        <div class="flex gap-3">

              <button @click="showAllQrCode" class="mr-2 border-2 btn border-dark">Export QR codes</button>
              <router-link to="/fleet/add" class="btn btn-primary" v-if="userHasPermission('vehicles001')">
                Add Vehicle
              </router-link>
        </div>
      </template>
      <template slot="tabs">
        <div class="container">
          <div class="pt-3 pb-1 row">
            <div class="col justify-self-center align-self-center flex-column">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <router-link :to="{ name: 'FleetVehicleList' }" class="nav-link" exact-active-class="active">Vehicles</router-link>
                </li>
                <li class="nav-item" >
                  <router-link :to="{ name: 'InspectionDays' }" class="nav-link" exact-active-class="active">Inspection Days</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </page-header>
  
  <page-route-view :showBackBtn="false" :loading="false">
		</page-route-view>
  </div>
</template>



<script setup>
import Swal from 'sweetalert2'
import { userHasPermission } from '@/composables/core/permissions'
import PageHeader from '@/components/layout/PageHeader'
import PageRouteView from '@/components/layout/PageRouteView'

const showAllQrCode = () =>
{
  Swal.fire({
    icon: 'question',
    title: 'Export all vehicle QR codes',
    text: 'Are you sure you want to export all vehicle QR codes?',
    showConfirmButton: true,
    showCancelButton: true,
    preConfirm: () =>
    {
      const link = `${process.env.VUE_APP_API_URL}/v1/vehicles/qrimages?page=1&limit=${this.totalRecords}&status=active`
      window.open(link, '_blank')
    },
    allowOutsideClick: () => !Swal.isLoading()
  })
}


</script>

<style lang="scss" scoped>
@import "../../../assets/scss/nav-tabs";
::v-deep {
  .b-table {
    & thead {
      tr>th {
        background: transparent;
      }
    }
  }
}

.b-pagination {
  margin-bottom: 0;
}

.table-responsive {
  margin-bottom: 0;
}
</style>
